import http from './http'
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
// // get请求
// export function getListAPI(params){
//     return http.get(`${resquest}/getList.json`,params)
// }
// // post请求
// export function postFormAPI(params){
//     return http.post(`${resquest}/postForm.json`,params)
// }
// // put 请求
// export function putSomeAPI(params){
//     return http.put(`${resquest}/putSome.json`,params)
// }
// // delete 请求
// export function deleteListAPI(params){
//     return http.delete(`${resquest}/deleteList.json`,params)
// }


export default {
   

    // 省市区
    areaindex_all(params) {
        return http.post(`/addons/filing/area/index_all`, params)
    },
    // 发送短信 
    Smssend(params) {
        return http.post(`/api/Sms/send`, params)
    },
    // 账号密码登录
    userlogin(params) {
        return http.post(`/api/user/login`, params)
    },
    // 验证码登录
    usermobilelogin(params) {
        return http.post(`/api/user/mobilelogin`, params)
    },
    // 注册  
    userregister(params) {
        return http.post(`/api/user/register`, params)
    },
    // 订单列表 
    orderindex(params) {
        return http.get(`/addons/filing/order/index`, params)
    },
    // 商品列表 (证件类型)
    goodsindex(params) {
        return http.get(`/addons/filing/goods/index`, params)
    },
    // 提交订单 
    ordercreate(params) {
        return http.post(`/addons/filing/order/create`, params)
    },
    // 订单支付
    payprepay(params) {
        return http.post(`/addons/sum/pay/prepay`, params)
    }, 
    // 订单详情
    orderpreview(params) {
        return http.get(`/addons/filing/order/preview`, params)
    },  

    //  上传资料  
    orderupdate(params) {
        return http.post(`/addons/filing/order/update`, params)
    },  

    //  初始化信息 
    indexOInit(params) {
        return http.post(`/addons/sum/index/init`, params)
    },  

    // 商品分类  
    goodscategory(params) {
        return http.get(`/addons/filing/goods/category`, params)
    },  

    // 协议  
    richtextDetail(params) {
        return http.get(`/addons/sum/richtext/detail`, params)
    },  


     // 添加留言  
     order_messageCreate(params) {
        return http.post(`/addons/filing/order_message/create`, params)
    },  

    // 订单留言列表
    order_messageIndex(params) {
        return http.get(`/addons/filing/order_message/index`, params)
    },  
}


