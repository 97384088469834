<template>
    <div class="container">
        <div class="pageTop">
            <div class="left">{{ info.name }}</div>
        </div>


        <div class="pageCon">
            <div class="title">使用手机号码注册</div>

            <div class="moudle">
                <div class="left">
                    <img src="../../assets/loginIcon01.png" mode=""></img>
                </div>
                <div class="right">
                    <input type="text" placeholder="请输入手机账号" v-model="mobile" />
                </div>
            </div>



            <div class="moudle">
                <div class="left">
                    <img src="../../assets/loginIcon02.png" mode=""></img>
                </div>
                <div class="right">
                    <input type="text" placeholder="请输入验证码" v-model="code" />
                    <div class="btn" v-if="isSeconds">{{ seconds }}s</div>
                    <div class="btn" v-else @click="getCode">获取验证码</div>
                </div>
            </div>


            <div class="moudle">
                <div class="left">
                    <img src="../../assets/loginIcon03.png" mode=""></img>
                </div>
                <div class="right">
                    <input type="text" placeholder="请输入公司名称" v-model="company_name" />
                </div>
            </div>


            <div class="moudle">
                <div class="left">
                    <img src="../../assets/loginIcon04.png" mode=""></img>
                </div>
                <div class="right">
                    <input type="password" placeholder="请输入密码" v-model="password" />
                </div>
            </div>


            <div class="moudle">
                <div class="left">
                    <img src="../../assets/loginIcon05.png" mode=""></img>
                </div>
                <div class="right">
                    <input type="password" placeholder="请再次输入密码" v-model="passwordAgin" />
                </div>
            </div>


            <div class="submit" @click="submit">
                注册
            </div>




            <div class="agreement" @click="changeisAgrement">
                <div class="left">
                    <img src="../../assets/blueSlt.png" mode="" v-if="isAgrement"></img>
                    <img src="../../assets/unSlt.png" mode="" v-else></img>

                </div>
                <div class="right">我已阅读并同意 <span @click.stop="goRt('register')">《资质开发注册协议》</span> </div>
            </div>

            <div class="account" @click="goLogin">已有账号，<span>立即登录</span> </div>


        </div>





       <hello></hello>


    </div>
</template>


<script>
import api from '@/utils/api.js'
import hello from '@/components/HelloWorld.vue'
export default {
    components:{
        hello
    },
    data() {
        return {
            isAgrement: false,

            isSeconds: false,
            seconds: 60,
            mobile: "", //手机号
            code: "", //验证码
            company_name: "", //公司名称
            password: '', //密码
            passwordAgin: "", //再次输入密码

            info: {},
        }
    },
    mounted() {
        let that = this
        if (that.$route.query.channel_user_id) {
            localStorage.setItem(channel_user_id, that.$route.query.channel_user_id)

        }

        that.getIndex()


    },
    methods: {

        getIndex() {
            let that = this
            api.indexOInit().then(res => {
                console.log(res, '基本信息');
                localStorage.setItem('sum', res.data.sum.name)
                that.info = res.data.sum
                if (localStorage.getItem('token')) {
                    that.$router.push({ path: '/form' })
                }

            })
        },

        changeisAgrement() {
            let that = this
            that.isAgrement = !that.isAgrement
        },
        goRt(data) {
            let that = this
that.$router.push({path:'/rt',query:{type:data}})
        },
        goLogin() {
            let that = this
            that.$router.push({ path: '/login', params: {} })
        },
        getCode() { //获取验证码
            let that = this

            if (!that.mobile) {
                this.$message.error('请先输入手机号')
                return false
            }
            let data = {
                mobile: that.mobile,
                event: 'register'
            }
            api.Smssend(data).then(res => {
                if (res.code == 1) {
                    this.$message.success('发送成功')
                    that.isSeconds = true
                    that.seconds = 60
                    setInterval(() => {
                        if (that.seconds > 1) {
                            that.seconds--
                        } else {
                            that.isSeconds = false
                            clearInterval()
                        }
                    }, 1000)
                }
            }).catch(err => console.log(err))
        },

        submit() {
            let that = this
            if (!that.mobile) {
                this.$message.error('请先输入手机号')
                return false
            }
            if (!that.code) {
                this.$message.error('请先输入验证码')
                return false
            }
            if (!that.company_name) {
                this.$message.error('请输入公司名称')
                return false
            }
            if (!that.password) {
                this.$message.error('请输入密码')
                return false
            }
            if (that.password != that.passwordAgin) {
                this.$message.error('两次输入密码不一致')
                return false
            }
            if (!that.isAgrement) {
                this.$message.error('请阅读并勾选《资质开发注册协议》')
                return false
            }
            let data = {
                username: that.mobile,
                mobile: that.mobile,
                code: that.code,
                company_name: that.company_name,
                password: that.password,
            }
            api.userregister(data).then(res => {
                if (res.code == 1) {
                    that.$message.success('注册成功')
                    setTimeout(() => {
                        that.login()
                    }, 500)
                }
            })
        },
        login() {
            let that = this
            let data = {
                password: that.password,
                account: that.mobile
            }
            api.userlogin(data).then(res => {
                if (res.code == 1) {
                    this.$message.success('登录成功')
                    localStorage.setItem('token', res.data.userinfo.token)
                    setTimeout(() => {
                        that.$router.push({ path: '/form' })
                    }, 1000)
                }
            }).catch(err => console.log(err))
        }
    }
}
</script>

<style scoped>
@import url('./index.css');
</style>