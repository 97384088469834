import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/login/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/form',
    name: 'form',
    component: () => import('../views/home/form.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/my/index.vue')
  },
  {
    path: '/rt',
    name: 'rt',
    component: () => import('../views/login/rt.vue')
  },
  {
    path: '/message',
    name: 'message',
    component: () => import('../views/my/message.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/my/order.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
