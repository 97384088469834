<template>
  <div class="hello">
    <span @click="goJump('https://beian.miit.gov.cn/')">{{info.beian}}</span>   <span>{{info.edi}}</span>   <span @click="goJump('https://beian.mps.gov.cn/#/query/webSearch')">{{info.registerSystemInfo}}</span>  
    
  </div>
</template>

<script>
import api from '@/utils/api'
export default {
  data(){
    return {
      info:{},
    }
  },
mounted(){
  let that = this
  that.getIndex()
},
  methods:{
    getIndex() {
            let that = this
            api.indexOInit().then(res => {
                console.log(res, '基本信息');
               
                that.info = res.data.sum
               

            })
        },
        goJump(url){
          window.open(url)
        }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .hello{
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    text-align: center;

  }
  .hello span{
    margin: 0 15px;
    cursor: pointer;
  }
</style>
